import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";

import BannerImg from "../images/landing_banner_img.png";
import ReviewImg from "../images/landing_review_img.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const LandingIntroModule = loadable(() => import("../components/LandingIntroModule/LandingIntroModule"));
const TileBlockReview = loadable(() => import("../components/TileBlockReview/TileBlockReview"));
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const TileBlockRight = loadable(() => import("../components/TileBlockRight/TileBlockRight"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));

const LandingPage = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <Banner 
                bannerImg={BannerImg}
                tag={"landing-page"}
            />

            <LandingIntroModule />

            <TileBlockReview
                reviewtext={"“Imagine if the clothing company Boden diversified into property sales - you'd expect colourful scatter cushions and a chocolate labrador dozing by a fire. That's the welcoming look radiated by Mr and Mrs Clarke. <br /><br />I hadn’t imagined I would be recommending an estate agency. Mr and Mrs Clarke changed my preconceptions. Their whole approach was refreshing and their attention to detail was incredible.”"}
                reviewauthor={"Grace, Brighton"}
                reviewImg={ReviewImg}
                tag={"landing-page"}
            />

            <PatternBanner 
                tag = "blue"
                title = "Our Selling statistics"
                desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
                statsModule = "stats-module"
            />

            <FeaturedProperties
                tag="landing-page"
            />

            <TileBlock />

            <TileBlockRight />

            <PatternBanner 
                tag = "brown"
                title = "start your journey"
                desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
            />
        </Layout>
    )
}

export default LandingPage